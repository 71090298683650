import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

    state: {
        qrCode: null
    },

    mutations: {
        updateQRCodeURL(state, payload) {
            state.qrCode = payload;
        }
    }
});
