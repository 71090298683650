<template>
    <v-app>
        <v-main>
            <v-container class="fill-height">
                <v-container>
                    <v-row
                        no-gutters
                        align="center"
                        justify="center"
                    >
                        <div class="text-h2 font-weight-bold white--text pa-2">
                            {{ app.name }}
                        </div>
                    </v-row>
                    <v-row
                        no-gutters
                        align="center"
                        justify="center"
                    >
                        <v-img
                            :src="getQRCode(app.url)"
                            :alt="app.version"
                            max-height="300"
                            max-width="300"
                        />
                    </v-row>
                    <v-row
                        no-gutters
                        align="center"
                        justify="center"
                    >
                        <div class="text-h5 black-text pa-4">
                            {{ app.version }}
                        </div>
                    </v-row>
                </v-container>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import QRCode from 'qrcode';

    export default {
        name: 'App',

        metaInfo: {
            meta: [
                { name: 'theme-color', content: '#A9001E' }
            ]
        },

        data: () => ({
            app: {
                name: 'SOL',
                version: '5.5 (21.11.17)_#2',
                url: 'https://sol.telebyte.io/app/manifest.plist'
            }
        }),

        methods: {
            getQRCode(url) {
                const prefix = 'itms-services://?action=download-manifest&url=';

                QRCode.toDataURL(`${prefix}${url}`).then(dataUrl => {
                    this.$store.commit('updateQRCodeURL', dataUrl);
                });

                return this.$store.state.qrCode;
            }
        }
    };

</script>

<style lang="scss" scoped>
    .v-main {
        background-image: url("assets/sol-back-red.png");
        background-size: cover;
        background-position: center;
    }
</style>
